import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Input, Card } from 'antd';
import axios from 'axios';

import { navigate } from "gatsby";

import { ActionTypes, dispatch, useGlobalState } from '../../state';
import { LoginRequest } from '../../service';

// @ts-ignore
import styles from "./login.module.css";

// @ts-ignore
import Conviviallogo from "../../../static/conviviallogo.inline.svg";

import { login, validateToken } from "../../state/actions";

export interface ILoginInformation {
    username?: string;
    password?: string;
}

const loginForm = (props) => {

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form;

    // Only show error after a field is touched.
    const usernameError = isFieldTouched('username') && getFieldError('username');
    const passwordError = isFieldTouched('password') && getFieldError('password');

    useEffect(() => {
        props.form.validateFields();
    }, []);

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some((field) => fieldsError[field]);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                console.log('Form is valid. Lets login');
                const response = await login({loginRequest: {username: values.username, password: values.password, remember: false } });
            }
        });
    };

    return (

        <Form layout="vertical" onSubmit={handleSubmit}>
            <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Please input your username!' }],
                })(
                    <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Username"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                    <Input
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Password"
                    />
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                    Log in
              </Button>
            </Form.Item>
        </Form>
    );
};

function Login() {
    const HLoginForm = Form.create({ name: 'horizontal_login' })(loginForm);
    return (
        <div className={styles.container}>
            <Card title={<Conviviallogo style={{width: 100, padding: 0, margin: 0}} />} extra="" style={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", borderRadius: "10px"}}>
                <HLoginForm />
            </Card>
        </div>
    );
}

export default Login;
