import React, { useEffect } from 'react';
import Login from '../components/Login';
import { useGlobalState } from '../state';
import { validateToken } from '../state/actions';
import { navigate } from '@reach/router';

function Index({ children, location }) {

    const [token] = useGlobalState('token');

    useEffect(() => {
        const isValid = validateToken(token, location.pathname, navigate);
    }, [token]);

    return (
        <Login />
    )
}

export default Index;